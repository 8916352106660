import styled from "styled-components";

export const RegularButton = styled.button`
  background: ${({ primary }) => (primary ? "#EB30AD" : "#2FA42F")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "16px 40px" : "10px 32px")};
  color: #fff;
  font-size: ${({ big }) => (big ? "20px" : "16px")};
  font-weight: 500;
  outline: none;
  border: none;
  min-width: 100px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s !important;
  letter-spacing: 0.8px;
  border-radius: ${({ round }) => (round ? "50px" : "none")};
  text-align: center;
  &:hover {
    background: ${({ primary }) => (primary ? "#EC72C4" : "#f26a2e")};
    transform: translateY(-2px);
  }
  &:disabled {
    opacity: 50%;
    transform: none;
    cursor: default;
    &:hover {
      background-color: ${({ primary }) => (primary ? "#EB30AD" : "#2FA42F")};
    }
  }

  @media screen and (max-width: 950px) {
    width: 70vw;
    height: 50px;
  }
`;
