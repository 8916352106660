import React, { useState, useEffect, useContext } from "react";
import { IoMdLogOut } from "react-icons/io";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { RegularButton } from "./RegularButton";
import { toast } from "react-toastify";
import { DayContext } from "../context/DayContext";

const Header = ({ setAuth, isAuthenticated }) => {
  const { name } = useContext(DayContext);
  const { url } = useContext(DayContext);
  const [clicked, setClicked] = useState(false);
  const [scroll, setScroll] = useState("unset");
  // const [name, setName] = useState("");
  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    setAuth(false);
    toast.success("Du är nu utloggad, vi ses imorgon igen!");
  };

  const getName = async () => {
    try {
      const response = await fetch(`${url}/api/v1/ophella/dashboard/`, {
        method: "GET",
        headers: { token: localStorage.token },
      });

      const parseRes = await response.json();
      //  setName(parseRes.user_name)
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getName();
  }, []);

  useEffect(() => {
    if (clicked === true) {
      document.body.style.overflow = "hidden";
    } else if (clicked === false) {
      document.body.style.overflow = "unset";
    }
  }, [clicked]);

  return (
    <Main>
      {isAuthenticated ? (
        <>
          <MenuWrapper>
            <BarWrapper onClick={() => setClicked(!clicked)}>
              <Bar className={clicked ? "isClicked" : ""} />
              <Bar className={clicked ? "isClicked" : ""} />
              <Bar className={clicked ? "isClicked" : ""} />
            </BarWrapper>
            <MobileMenu
              className={clicked ? "isClickedBackground" : "isClosed"}
            ></MobileMenu>

            <NavLinkHeader to="/" style={{ justifySelf: "flex-start" }}>
              Ophella
            </NavLinkHeader>
            <VersionMobile style={{ marginRight: "1rem" }}>
              version 1.1
            </VersionMobile>
          </MenuWrapper>

          <Navbar className={clicked ? "isClicked" : "isClosed"}>
            <NavLink
              className={clicked ? "isClicked" : "isClosed"}
              onClick={() => setClicked(!clicked)}
              to="/dashboard"
            >
              Rapportera tid
            </NavLink>
            <NavLink
              className={clicked ? "isClicked" : "isClosed"}
              onClick={() => setClicked(!clicked)}
              to={`/dashboard/mitt-konto/${name?.toLowerCase()}`}
            >
              Mitt konto
            </NavLink>
          </Navbar>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Version style={{ marginRight: "1rem" }}>version 1.1</Version>
            <RegularButton
              primary="true"
              round="true"
              onClick={(e) => logout(e)}
            >
              Logga ut
            </RegularButton>
          </div>
        </>
      ) : (
        <></>
      )}
    </Main>
  );
};

const MenuWrapper = styled.div`
  text-align: center;
`;

const VersionMobile = styled.p`
  padding-top: 0.25rem;
  margin: 0 !important;
  font-weight: 300;
  @media screen and (min-width: 950px) {
    display: none;
  }
`;

const Version = styled.p`
  margin-right: 1rem;

  @media screen and (max-width: 950px) {
    display: none;
  }
`;
const MobileMenu = styled.div`
  transition: all 0.5s ease;
  position: absolute;
  background-color: #230546;
  display: none;
  margin-top: 0em;

  @media screen and (max-width: 950px) {
    display: block;
  }
  &.isClickedBackground {
    transition: all 0.5s ease;
    transform: translateX(0px);
    z-index: 10;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    opacity: 1;
  }
  &.isClosed {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    transition: all 0.5s ease;
    transform: translateX(-800px);
  }
`;

const BarWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  z-index: 11;
  display: none;

  @media screen and (max-width: 950px) {
    display: block;
  }
`;

const Bar = styled.div`
  border-bottom: 3px solid white;
  width: 30px;
  transition: 0.5s ease;
  opacity: 1 !important;
  transform: translateX(0px);
  opacity: 1 !important;
  :nth-child(1) {
    margin-top: 0em;
    &.isClicked {
      margin-top: 0.5em;
      transform: rotate(45deg);
    }
  }
  :nth-child(2) {
    margin-top: 0.39em;
    &.isClicked {
      display: none;
    }
  }
  :nth-child(3) {
    margin-top: 0.39em;
    &.isClicked {
      transform: rotate(135deg);
      margin-top: -0.2em;
    }
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  justify-items: space-evenly;
  align-content: space-evenly;
  align-items: space-evenly;
  padding-top: 1em;
  padding-bottom: 3em;
  margin: 0 auto;
  height: 100%;

  @media screen and (max-width: 950px) {
    padding: 1em 0 2em 0em;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
  }

  ${RegularButton} {
    @media screen and (max-width: 950px) {
      margin-top: 1.2em;
      margin-bottom: 1em;
    }
  }
`;

const Navbar = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 950px) {
    &.isClicked {
      position: absolute;
      z-index: 15;
      flex-direction: column !important;
      transform: translateX(0px);
      top: 35%;
    }
    &.isClosed {
      position: absolute;
      transform: translateX(-800px);
    }
  }
`;

const NavLinkHeader = styled(Link)`
  color: #fbfbfb;
  margin-left: 0em;
  font-weight: bold;
  text-decoration: none;
  font-size: 1.5em !important;

  @media screen and (max-width: 950px) {
    margin: 0 auto;
  }
`;

const NavLink = styled(Link)`
  color: #fbfbfb;
  margin-left: 1em;
  margin-right: 1em;
  font-weight: bold;
  text-decoration: none;
  font-size: 1.2em;

  @media screen and (max-width: 950px) {
    font-size: 2em !important;
    width: 100vw;
    text-align: center;
    &.isClicked {
      position: absolute;
      z-index: 15;
      flex-direction: column !important;
      transform: translateX(0px);
    }
    &.isClosed {
      position: absolute;
      transform: translateX(-800px);
    }

    :nth-child(1) {
      transition: all 0.5s ease;
      margin-top: 0.5em;
    }
    :nth-child(2) {
      transition: all 0.8s ease;
      margin-top: 2.5em;
    }
  }
`;

export default Header;
