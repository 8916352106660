import React, { useEffect, useContext } from "react";
import { GlobalStyle } from "../styles/GlobalStyles";
import Header from "./Header";
import Footer from "./Footer";
import { useHistory } from "react-router-dom";
import { WorkerContext } from "../context/WorkerContext";

const Layout = ({ children, setAuth, isAuthenticated }) => {
  // const {loggedIn, setLoggedIn} = useContext(WorkerContext);
  //
  // let history = useHistory();
  // useEffect(() => {
  //   const checkLogin = () => {
  //     if (loggedIn != true) {
  //       history.push(`/`)
  //     }
  //   }
  //   checkLogin();
  // }, [])

  return (
    <>
      <GlobalStyle />
      <Header setAuth={setAuth} isAuthenticated={isAuthenticated} />

      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
