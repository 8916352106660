import React, { Suspense, lazy, useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/Layout";
import styled from "styled-components";
import "./styles/styles.css";
import { DayContextProvider } from "./context/DayContext";
import { WorkerContextProvider } from "./context/WorkerContext";
import CircularProgress from "@material-ui/core/CircularProgress";
const Login = lazy(() => import("./routes/Login"));
const Home = lazy(() => import("./routes/Home"));
const Register = lazy(() => import("./routes/Register"));
const UpdateDayPage = lazy(() => import("./routes/UpdateDayPage"));
const DayDetailPage = lazy(() => import("./routes/DayDetailPage"));
const AccountPage = lazy(() => import("./routes/AccountPage"));
const NotFound = lazy(() => import("./routes/NotFound"));

toast.configure();

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const url = window.location.origin == "http://localhost:3000" ? window.location.origin.slice(0,-4) + "3006" : window.location.origin;

  const setAuth = (boolean) => {
    setIsAuthenticated(boolean);
  };

  const isAuth = async () => {
    try {
      const response = await fetch(`${url}/api/v1/ophella/auth/is-verify`, {
        method: "GET",
        headers: { token: localStorage.token },
      });

      const parseRes = await response.json();
      parseRes === true ? setIsAuthenticated(true) : setIsAuthenticated(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    isAuth();
  }, []);

  return (
    <DayContextProvider>
      <WorkerContextProvider>
        <Main>
          <Router>
            <Suspense
              fallback={
                <LoadingWrapper>
                  <CircularProgress />
                </LoadingWrapper>
              }
            >
              <Layout setAuth={setAuth} isAuthenticated={isAuthenticated}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={(props) =>
                      !isAuthenticated ? (
                        <Login {...props} setAuth={setAuth} />
                      ) : (
                        <Redirect to="/dashboard" />
                      )
                    }
                  />

                  <Route
                    exact
                    path="/login"
                    render={(props) =>
                      !isAuthenticated ? (
                        <Login {...props} setAuth={setAuth} />
                      ) : (
                        <Redirect to="/dashboard" />
                      )
                    }
                  />

                  <Route
                    exact
                    path="/register"
                    render={(props) =>
                      !isAuthenticated ? (
                        <Register {...props} setAuth={setAuth} />
                      ) : (
                        <Redirect to="/login" />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/dashboard"
                    render={(props) =>
                      isAuthenticated ? (
                        <Home {...props} />
                      ) : (
                        <Redirect to="/login" />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/dashboard/:id/update"
                    render={(props) =>
                      isAuthenticated ? (
                        <UpdateDayPage {...props} setAuth={setAuth} />
                      ) : (
                        <Redirect to="/login" />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/dashboard/:id"
                    render={(props) =>
                      isAuthenticated ? (
                        <DayDetailPage {...props} setAuth={setAuth} />
                      ) : (
                        <Redirect to="/login" />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/dashboard/mitt-konto/:id"
                    render={(props) =>
                      isAuthenticated ? (
                        <AccountPage {...props} />
                      ) : (
                        <Redirect to="/login" />
                      )
                    }
                  />
                  <Route
                    render={(props) =>
                      !isAuthenticated ? (
                        <NotFound {...props} setAuth={setAuth} />
                      ) : (
                        <Redirect to="/dashboard" />
                      )
                    }
                  />
                </Switch>
              </Layout>
            </Suspense>
          </Router>
        </Main>
      </WorkerContextProvider>
    </DayContextProvider>
  );
};

const Main = styled.div`
  font-family: "Roboto", "Helvetica", sans-serif;
  color: #fbfbfb;
  background-color: #3e1e69;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;

  h1 {
    letter-spacing: 1.2px;
    font-size: 2em;
  }

  h2 {
    letter-spacing: 1.2px;
    font-size: 1.5em;
  }

  h3 {
    letter-spacing: 1.2px;
    font-size: 1.2em;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
`;

export default App;
