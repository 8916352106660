import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
*{
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    h1{
        font-size: 2em !important;
    }

    h2 {
        font-size: 1.5em !important;
    }

    font-size: 1em !important;
}

input {
    height: 25px !important;
}

@media screen and (max-width: 950px) {
    input {
        width: 70vw !important;
    }
}
`;
