import React, {useState, createContext} from "react";

export const WorkerContext = createContext();

export const WorkerContextProvider = props => {
    const [workerHours, setWorkerHours] = useState([])
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);

    return(
        <WorkerContext.Provider value={{workerHours, setWorkerHours, username, setUsername, password, setPassword, loggedIn, setLoggedIn}}>
            {props.children}
        </WorkerContext.Provider>
    )
}